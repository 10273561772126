import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.scss']
})
export class ViewVideoComponent implements OnInit {
  code: string;

  triedPassword = false;

  notFound = false;
  passwordRequired = false;
  showVideo = false;

  unlockVideoForm: FormGroup;
  password: string;

  video: any = false;
  videoUrl: any;
  downloadUrl: string;

  refreshTime: number = 20;

  loading = false;

  loadingInterval: any;

  pageLoading = true;

  constructor(private route: ActivatedRoute, private http: HttpClient, private fb: FormBuilder, private sanitizer: DomSanitizer) {
    this.createForm();
  }

  ngOnInit() {
    this.code = this.route.snapshot.params.code;

    this.loadVideo();
  }

  loadVideo() {
    this.notFound = false;
    this.showVideo = false;
    this.loading = true;

    if(this.loadingInterval) clearInterval(this.loadingInterval);

    console.log(encodeURIComponent(btoa(this.password)))

    var url = 'https://api.replay.nextbase.co.uk/upload/video/' + this.code + (this.password !== '' && this.password !== undefined ? '?password=' + encodeURIComponent(btoa(this.password)) : '');
    var retry = 0;

    // Timeout so it loads for a little while... probablu a bit shitty
    setTimeout(() => {

      const tempUrl = url + (retry++ > 0 ? "&retry=" + retry : "");
      this.http.get(tempUrl).subscribe(response => {

        if (++retry == 1) url += "&retry="

        this.video = response;
        console.log(this.video);

        if (!this.video.streamReady || !this.video.downloadReady) {
          this.refreshTime = 20;
  
          this.loadingInterval = setInterval(() => {
            this.refreshTime = this.refreshTime - 1;

            if (this.refreshTime === 0) {
              clearInterval(this.loadingInterval);
              this.loadVideo();
            }
          }, 1000);
        }
  
        this.passwordRequired = false;
        this.loading = false;
        this.pageLoading = false;
  
      }, errResponse => {
  
        if (errResponse.status === 404) {
          this.notFound = true;
        } else if (errResponse.status === 401) {
          this.passwordRequired = true;
        } else {
          console.error(errResponse);
          console.dir(errResponse);
        }
  
        this.loading = false;
        this.pageLoading = false;
  
      });

    }, 500)
    
  }

  createForm() {
    this.unlockVideoForm = this.fb.group({
      password: ['', Validators.required]
    });
  }

  submit() {
    this.password = this.unlockVideoForm.value.password;
    this.triedPassword = true;
    this.loadVideo();
  }

}
