import { Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { VideoComponent } from './video/video.component';
import { ViewVideoComponent } from './view-video/view-video.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UserResolver } from './user/user.resolver';
import { AuthGuard } from './core/auth.guard';
import { SetupAlexaSkillComponent } from './setup-alexa-skill/setup-alexa-skill.component';

export const rootRouterConfig: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent,  resolve: { data: UserResolver}},
  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthGuard] },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthGuard] },
  { path: 'video', component: VideoComponent, canActivate: [AuthGuard] },
  { path: 'video/:code', component: ViewVideoComponent, canActivate: [AuthGuard] },
  { path: 'setup-alexa-skill', component: SetupAlexaSkillComponent, canActivate: [AuthGuard] },
];
