import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { keyframes } from '@angular/animations';
import { CssKeyframesPlayer } from '@angular/animations/browser/src/render/css_keyframes/css_keyframes_player';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  allowedFunctionKeys = [
    'Backspace', 'Delete', 'ArrowRight', 'ArrowDown', 'Tab', 'ArrowLeft', 'ArrowUp',
  ];
  allowedCodeKeys = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ];

  file: any;
  canSubmit = false;

  block = 'block-1';

  percent = 0;

  shakeAnimation = false;

  uploadConfig: UploadParams = {
    sas: '?st=2019-06-03T12%3A04%3A13Z&se=2019-06-04T12%3A04%3A13Z&sp=rw&sv=2018-03-28&sr=c&sig=%2B2dpOj5%2FF%2FePKfDQ7BNAz6WhlKgCYqaUWX9VjQPnJfA%3D',
    storageAccount: 'mynextbasevideo',
    containerName: 'web-upload'
  };

  uploadForm: FormGroup;

  codeForm: FormGroup;

  @ViewChild('codeInput0') codeInput0: ElementRef;
  @ViewChild('codeInput1') codeInput1: ElementRef;
  @ViewChild('codeInput2') codeInput2: ElementRef;
  @ViewChild('codeInput3') codeInput3: ElementRef;
  @ViewChild('codeInput4') codeInput4: ElementRef;

  constructor(private router: Router, private blob: BlobService, private http: HttpClient, private fb: FormBuilder) { }

  ngOnInit() {
    this.codeInput0.nativeElement.focus();

    this.uploadForm = this.fb.group({
      email: ['', Validators.email ],
      submit: [true]
    });
   // this.uploadForm.controls.submit.setValue(true);
  }

  keyDownCodeEntry($event, inputIndex) {

    if (this.allowedCodeKeys.indexOf($event.key) > -1) {
      if (this.getInput(inputIndex).nativeElement.value.length > 0) {
        return false;
      }
    } else {
      if (this.allowedFunctionKeys.indexOf($event.key) < 0) {
        return false;
      }
    }

    const currentInput: ElementRef = this.getInput(inputIndex);

    if ($event.key === 'Backspace' || $event.key === 'Delete') {
      if (currentInput.nativeElement.value === '') {
        return this.focusPrev(inputIndex);
      }
    }
  }
  keyUpCodeEntry($event, inputIndex) {

    if (this.allowedCodeKeys.indexOf($event.key) < 0 && this.allowedFunctionKeys.indexOf($event.key) < 0) {
      return false;
    }

    const currentInput: ElementRef = this.getInput(inputIndex);

    if ($event.key === 'Enter') {

      return this.viewVideo();

    } else if (['ArrowRight', 'ArrowDown', 'Tab'].indexOf($event.key) > -1) {

      return this.focusNext(inputIndex);

    } else if (['ArrowLeft', 'ArrowUp'].indexOf($event.key) > -1) {

      return this.focusPrev(inputIndex);

    } else if ($event.key.length === 1) { // i.e. a, b, 1. 9 etc

      this.focusNext(inputIndex);
    }

  }
  getInput(inputIndex) {
    switch (inputIndex) {
      case 0:
        return this.codeInput0;
      case 1:
        return this.codeInput1;
      case 2:
        return this.codeInput2;
      case 3:
        return this.codeInput3;
      case 4:
        return this.codeInput4;
    }
  }
  focusNext(inputIndex) {
    switch (inputIndex) {
      case 0:
        return this.codeInput1.nativeElement.focus();
      case 1:
        return this.codeInput2.nativeElement.focus();
      case 2:
        return this.codeInput3.nativeElement.focus();
      case 3:
        return this.codeInput4.nativeElement.focus();
    }
  }
  focusPrev(inputIndex) {
    switch (inputIndex) {
      case 4:
        return this.codeInput3.nativeElement.focus();
      case 3:
        return this.codeInput2.nativeElement.focus();
      case 2:
        return this.codeInput1.nativeElement.focus();
      case 1:
        return this.codeInput0.nativeElement.focus();
    }
  }
  getPrevElement(inputIndex) {
    switch (inputIndex) {
      case 4:
        return this.codeInput3.nativeElement;
      case 3:
        return this.codeInput2.nativeElement;
      case 2:
        return this.codeInput1.nativeElement;
      case 1:
        return this.codeInput0.nativeElement;
    }
  }
  getCode() {
    let code = '';
    code += this.codeInput0.nativeElement.value;
    code += this.codeInput1.nativeElement.value;
    code += this.codeInput2.nativeElement.value;
    code += this.codeInput3.nativeElement.value;
    code += this.codeInput4.nativeElement.value;
    return code;
  }

  validateCode() {
    const code = this.getCode();
    return code.length === 5;
  }

  viewVideo() {
    if (this.validateCode()) {
      return this.router.navigateByUrl('/video/' + this.getCode().toUpperCase());
    }
  }

  onFilesAdded(files: File[]) {
    console.log(files);

    this.block = 'block-2';

    if (files.length === 1) {
      this.file = files[0];
    }
  }

  startUpload() {

    if (!this.uploadForm.valid) {

      this.validateAllFormFields(this.uploadForm);

      this.shakeAnimation = true;
      setTimeout(() => {
        this.shakeAnimation = false;
      }, 1000);

      return;
    }

    interface StartUploadResponse {
      blob_upload_url: string;
      finish_url: string;
    }

    this.http.post<StartUploadResponse>('https://api.nextbase.com/api/v1/video/upload/start', {

      filename: this.file.name,
      size: this.file.size,
      mimeType: this.file.type,
      email: this.uploadForm.value.email,
      canContact: this.uploadForm.value.submit

    }).subscribe(response => {

      this.upload(response.blob_upload_url, response.finish_url);
      this.block = 'block-3';
    }, (err) => {
      console.error(err);
    });

  }

  upload (blob_url, finish_url) {

    if (this.file !== null) {
      const uploadUrl = blob_url.split('?')[0];
      const sas = '?' + blob_url.split('?')[1];

      const config = {
        baseUrl: uploadUrl,
        sasToken: sas,
        file: this.file,
        complete: () => {
          this.finishUpload(finish_url);
        },
        error: (err) => {
          console.log('Error:', err);
        },
        progress: (percent) => {
          this.percent = percent;
        }
      };
      this.blob.upload(config);
    }
  }

  finishUpload(finishUrl) {

    interface FinishUploadResponse {
      code: string;
    }

    this.http.get<FinishUploadResponse>(finishUrl).subscribe(response => {

      this.router.navigate(['/video', response.code]);

    }, (err) => {
      console.error(err);
    });
  }

  isFieldValid(field: string) {
    return !this.uploadForm.get(field).valid && this.uploadForm.get(field).touched;
  }

  isFieldNotValid(field: string) {
    return !this.uploadForm.get(field).valid;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
