import { Component } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {style, state, animate, transition, trigger} from '@angular/animations';

@Component({
  selector: 'page-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class LoginComponent {

  loginForm: FormGroup;
  errorMessage = '';
  shakeAnimation = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });
  }

  tryFacebookLogin() {
    this.authService.doFacebookLogin()
      .then(res => {
        this.router.navigate(['/user']);
      });
  }

  tryGoogleLogin() {
    this.authService.doGoogleLogin()
      .then(res => {
        this.router.navigate(['/user']);
      });
  }

  tryLogin(value) {

    if (this.loginForm.valid) {

      this.authService.doLogin(value)
      .then(res => {
        this.router.navigate(['/user']);
      }, err => {
        console.log(err);

        switch (err.code) {
          case 'auth/user-not-found':
            this.errorMessage =
              'We couldn\'t find a user associated with the email address you entered, please try again or register for a new account.';
            break;
          case 'auth/wrong-password':
            this.errorMessage =
              'The password you entered is invaild or your account does not have a password set, please try again or use social login.';
            break;
          default:
            this.errorMessage =
              'A unknown error happend while trying to log you in, please try again.';
        }

      });

    } else {
      this.validateAllFormFields(this.loginForm);

      this.shakeAnimation = true;
      console.log(this.shakeAnimation);
      setTimeout(() => {
        this.shakeAnimation = false;
        console.log(this.shakeAnimation);
      }, 1000);
    }
  }

  isFieldValid(field: string) {
    return !this.loginForm.get(field).valid && this.loginForm.get(field).touched;
  }

  isFieldNotValid(field: string) {
    return !this.loginForm.get(field).valid;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
